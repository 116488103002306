import React, { useEffect, useState, useRef } from "react";
import { useAlert } from 'react-alert'
import { auth, app, signInWithEmailAndPassword, sendPasswordResetEmail } from "../firebase";
import "../App.css";
import CircleLoader from "../Components/CircleLoader";
import BuyersClub from "../Images/BuyersClub.png";
import Ecommerce from "../Images/Ecommerce.png";
import Widget from "../Images/Widget.png";

const Platform = ({title, description, image, active, page}) => {
    return (
        <div onClick={() => {window.location.href = active ? page : "https://calendly.com/brett-heed/15min?month=2022-06"}} className={`platform-div flex flex-col justify-center items-center bg-white rounded-2xl px-2 pt-2 pb-6 transition duration-500 hover:scale-110 cursor-pointer`}>
            <div className={`${active ? null : "opacity-30"} px-16`}>
                <img className="w-80 lg:w-100" src={image}/>
            </div>
            <div className={`${active ? null : "opacity-30"}`}>
                <h5 className="mt-4 text-center text-xl font-bold">{title}</h5>
                <p className="mt-2 text-sm text-center font-normal">{description}</p>
            </div>
            <button style={{backgroundColor: "#5096ff"}} className="mt-2 w-40 text-white text-base p-2 rounded-full">{active ? "Continue": "Learn More"}</button>

        </div>
    )
}


function Dashboard({}) {
    const hostname = "https://heed.herokuapp.com";
    // const hostname = "http://localhost:5000";
    const [loading, setLoading] = useState(true);

    const [ecommerce, setEcommerce] = useState(false);
    const [buyers, setBuyers] = useState(false);
    const [widget, setWidget] = useState(false);

    useEffect(() => {
        let token = localStorage.getItem("@token");
        let userId = localStorage.getItem("@user");
        // If no token go back to login
        if (!token || !userId) {
            window.location.href = "/";
        }

        // If token check the account (checks if buyers club / ecommerce)
        fetch(`${hostname}/api/user/${userId}` , { headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
        .then((response) => response.json())
        .then(async (response) => {
            if (response.success) {
                setEcommerce(response.ecommerce);
                setBuyers(response.buyers);
                setWidget(response.widget);
                setLoading(false);
            } else {
                return alert.show(response.error , {
                    timeout: 0,
                    type: 'error'
                });
            }
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            return alert.show(errorMessage , {
                timeout: 0,
                type: 'error'
            });
        });


    }, []);


    return (
        <div className="App Dashboard">
            {loading ? (
                <CircleLoader />
            ) : (
                <div style={{maxWidth: 960, margin: "auto"}}>
                    <h5 className="text-4xl text-center text-neutral-700">heed</h5>
                    <h1 className="text-5xl text-center text-white mt-20">There is a heed for everyone</h1>
                    <div className="flex justify-around flex-col lg:flex-row mt-8">
                        <Platform image={BuyersClub} title={"buyers club"} description={"Join the buyers club and start saving money today."} active={buyers}
                            page={"https://buyers.heed.chat"} 
                        />
                        <Platform image={Ecommerce} title={"ecommerce"} description={"Try our customizable ecommerce free for 6 months."} active={ecommerce} 
                            page={"https://ecommerce.heed.chat"}
                        />
                        <Platform image={Widget} title={"widget"} description={"Deploy our industry leading recommendation engine."} active={widget} 
                            page={"https://platform.heed.chat"}
                        />

                    </div>
                </div>
            )}
        </div>
    );
}

export default Dashboard;