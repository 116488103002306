import React, { useEffect, useState, useRef } from "react";
import { useAlert } from 'react-alert'
import { auth, app, signInWithEmailAndPassword, sendPasswordResetEmail } from "../firebase";
import "../App.css";

function Login({}) {
    const hostname = "https://heed.herokuapp.com";
    // const hostname = "http://localhost:5000";

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const alert = useAlert();


    useEffect(() => {
        let token = localStorage.getItem("@token");
        let userId = localStorage.getItem("@user");
        if (token && userId) {
            window.location.href = "/dashboard";
        } else {
            localStorage.removeItem("@token");
            localStorage.removeItem("@user");
        }
    }, []);


    const login = () => {
        if (!email || !password) {
            alert.show('Please fill in all fields' , {
                timeout: 0,
                type: 'error'
            });
        }
        signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
            // Signed in 
            const user = userCredential.user;
            if (!user.emailVerified) {
                // return alert.show('Please verify your email' , {
                //     timeout: 0,
                //     type: 'error'
                // });
            }
            let localToken = user.accessToken;
            window.localStorage.setItem("@token", localToken);
            window.localStorage.setItem("@user", user.uid);
            window.location.href = "/dashboard";
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            return alert.show(errorMessage , {
                timeout: 0,
                type: 'error'
            });
            // ..
        });
    }



    return (
        <div className="App Login">

            <div>
                    <h1 className="mb-6 text-3xl font-bold">Login</h1>
                    <input className="mt-4 p-2 py-2 pt-2.5 rounded-sm border border-gray-300" style={{width:"100%"}} value={email} type="email" onChange={((e) => {setEmail(e.target.value)})} placeholder="Email"></input>
                    <input className="mt-2 p-2 py-2 pt-2.5 rounded-sm border border-gray-300" style={{width:"100%"}} value={password} type="password" onChange={((e) => {setPassword(e.target.value)})} placeholder="Password"></input>
                    <a href="#" onClick={() => {
                        if (!email) {
                            return alert.show("Please enter a valid email!" , {
                                timeout: 0,
                                type: 'error'
                            });
                        }

                        sendPasswordResetEmail(auth, email)
                        .then(function () {
                            alert.show(`Password reset email sent!`);
                        }).catch(function (e) {
                            alert.show(e.toString() , {
                                timeout: 0,
                                type: 'error'
                            });
                        })

                        
                    }} style={{alignSelf:"flex-start", marginTop:15, marginBottom:5, fontSize:14, color: "#5096FF"}}>Reset password</a>
                    <button style={{backgroundColor: "#5096ff", width:"100%"}} className="pt-2.5 mt-4 text-white hover:opacity-80 font-medium p-2.5 rounded-md" onClick={() => {login()}} >Login</button>
                </div>

        </div>
    );
}

export default Login;